<template>
<!-- Single Courses Start -->
<div class="single-courses">
    <div class="courses-images">
        <router-link :to="{ name:'MyCourses',params:{slug:'plumbing-made-easy'}}"><img :src="course.image" alt="Courses"></router-link>
        <div class="courses-option dropdown">
            <button class="option-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                <span></span>
                <span></span>
                <span></span>
            </button>
            <ul class="dropdown-menu">
                <li><a href="#"><i class="icofont-share-alt"></i> Share</a></li>
                <li><a href="#"><i class="icofont-plus"></i> Create Collection</a></li>
                <li><a href="#"><i class="icofont-star"></i> Favorite</a></li>
                <li><a href="#"><i class="icofont-archive"></i> Archive</a></li>
            </ul>
        </div>
    </div>
    <div class="courses-content">
        <div class="courses-author">
            <div class="author">
                <div class="author-thumb">
                    <a href="#"><img :src="course.authorImage" alt="Author"></a>
                </div>
                <div class="author-name">
                    <a class="name" href="#">{{ course.authorName }}</a>
                </div>
            </div>
        </div>
        <h4 class="title"><a href="courses-details.html">{{ course.name}}</a></h4>

        <div class="courses-rating">
            <p>{{ course.percent }}% Complete</p>

            <div class="rating-progress-bar">
                <div class="rating-line" ref="progress"></div>
            </div>

            <div class="rating-meta">
                <span class="rating-star">
                        <span class="rating-bar" ref="ratingStar"></span>
                </span>
                <p>Your rating</p>
            </div>
        </div>
    </div>
</div>
<!-- Single Courses End -->


</template>
<script>
export default{
    name:"SingleCourseCard",
    props:['course'],
    mounted(){
        this.$refs.progress.style.width = this.course.percent + '%';
        //ratings
        let width = (this.course.rating/5.0) * 100 
        this.$refs.ratingStar.style.width = width + '%' 
    } 
}
</script>