<template>
<Header/>
    <Banner name="Courses" message="My Courses" />
    <!-- Courses Start -->
        <div class="section section-padding">
            <div class="container">

                <!-- Courses Category Wrapper Start  -->
                <div class="courses-category-wrapper">
                    <div class="courses-search search-2">
                        <input type="text" placeholder="Search here">
                        <button><i class="icofont-search"></i></button>
                    </div>

                    <ul class="category-menu">
                        <li><a class="active" href="#">All Courses</a></li>
                        <li><a href="#">Collections</a></li>
                        <li><a href="#">Wishlist</a></li>
                        <li><a href="#">Archived</a></li>
                    </ul>
                </div>
                <!-- Courses Category Wrapper End  -->

                <!-- Courses Wrapper Start  -->
                <div class="courses-wrapper-02">
                    <div class="row">
                        <div v-for="Course in Courses" :key="Course" class="col-lg-4 col-md-6">
                            <SingleCourseCard :course="Course" />
                        </div>
                    </div>
                </div>
                <!-- Courses Wrapper End  -->

            </div>
        </div>
        <!-- Courses End -->
<Footer/>
</template>
<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Banner from '@/components/Banner.vue';
import SingleCourseCard  from '@/components/Users/SingleCourseCard';
export default{
    name:"MyCourses",
    components:{
        Header, Footer , Banner,SingleCourseCard
    },
    data(){
        return{
            Courses:[
                {name:'Plumbing made eazy',percent:"45",authorName:'Jason Obi',authorImage:"https://images.pexels.com/photos/7907687/pexels-photo-7907687.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",image:"https://images.pexels.com/photos/1249611/pexels-photo-1249611.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",lectures:"39",time:"08 hr 15 mins",price:"389",regularPrice:"440",rating:"4.0"},
                {name:'Plumbing made eazy',percent:"45",authorName:'Jason Obi',authorImage:"https://images.pexels.com/photos/7907687/pexels-photo-7907687.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",image:"https://images.pexels.com/photos/1249611/pexels-photo-1249611.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",lectures:"39",time:"08 hr 15 mins",price:"389",regularPrice:"440",rating:"4.0"},
                {name:'Plumbing made eazy',percent:"45",authorName:'Jason Obi',authorImage:"https://images.pexels.com/photos/7907687/pexels-photo-7907687.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",image:"https://images.pexels.com/photos/1249611/pexels-photo-1249611.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",lectures:"39",time:"08 hr 15 mins",price:"389",regularPrice:"440",rating:"5.0"},
                {name:'Plumbing made eazy',percent:"45",authorName:'Jason Obi',authorImage:"https://images.pexels.com/photos/7907687/pexels-photo-7907687.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",image:"https://images.pexels.com/photos/1249611/pexels-photo-1249611.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",lectures:"39",time:"08 hr 15 mins",price:"389",regularPrice:"440",rating:"1.0"},
                {name:'Plumbing made eazy',percent:"45",authorName:'Jason Obi',authorImage:"https://images.pexels.com/photos/7907687/pexels-photo-7907687.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",image:"https://images.pexels.com/photos/1249611/pexels-photo-1249611.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",lectures:"39",time:"08 hr 15 mins",price:"389",regularPrice:"440",rating:"2.0"},
                {name:'Plumbing made eazy',percent:"45",authorName:'Jason Obi',authorImage:"https://images.pexels.com/photos/7907687/pexels-photo-7907687.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",image:"https://images.pexels.com/photos/1249611/pexels-photo-1249611.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",lectures:"39",time:"08 hr 15 mins",price:"389",regularPrice:"440",rating:"3.5"}
            ]
        }
    }
}
</script>